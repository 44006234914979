import React, { useEffect, useState } from "react"
import { FaFileDownload } from "react-icons/fa"

import Layout from "../../components/layout.component"
import Seo from "../../components/seo.component"
import { Link } from "gatsby"

const contentful = require("contentful")
const contentfulClient = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
})

const CurriculumGuide = props => {
  const [guide, setGuide] = useState(null)

  useEffect(() => {
    contentfulClient
      .getEntries({
        content_type: "link",
        "fields.id": "curriculum_guide",
      })
      .then(response => {
        setGuide(response.items[0])
      })
      .catch(console.error)
  }, [])

  return (
    <Layout className="success">
      <Seo title="Curriculum Guide Download" />
      <section>
        <div className="container" style={{ maxWidth: "750px" }}>
          <div
            className="has-text-centered is-flex is-flex-direction-column is-justify-content-center"
            style={{ minHeight: "50vh" }}
          >
            <h1 className="title has-text-success">
              Thank you for purchasing{" "}
              <span className="is-italic">Curriculum Guide</span> from Dan
              Parasky!
            </h1>
            <h2 className="subtitle">Download your file below and please be sure to save it to your device.</h2>
            {guide ? (
              <a
                className="button is-primary"
                href={guide.fields.file.fields.file.url}
                style={{ alignSelf: 'center', marginTop: '2rem' }}
                target="_blank"
                rel="noreferrer"
              >
                Download File <FaFileDownload className="ml-4" />
              </a>
            ) : (
              <div>
                <p className="is-size-5">
                  Retreiving your download link...
                </p>
                <p className="is-size-6">
                  If you still see this message after 30 seconds, please refresh and try again. If you still see this message after refreshing, please <Link to="/contact">contact Dan</Link>.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CurriculumGuide
